<template>
  <div>
    <div class="header">
      <van-nav-bar
        :title="$t('fund.title')"
        left-arrow
        @click-left="$router.go(-1)"
      >
      </van-nav-bar>
    </div>
    <div class="maincontent" style="background: #181e34;">
      <!-- 轮播图 -->
      <div class="swipe">
        <van-swipe class="first-swipe" :autoplay="3000">
          <van-swipe-item>
            <img :src="require('../../assets/img/swipe.png')" />
          </van-swipe-item>
          <van-swipe-item>
            <img :src="require('../../assets/img/swipe.png')" />
          </van-swipe-item>
          <van-swipe-item>
            <img :src="require('../../assets/img/swipe.png')" />
          </van-swipe-item>
          <template #indicator>
            <span></span>
          </template>
        </van-swipe>
      </div>
      <!-- 三个图标 -->
      <van-row class="icon-tiaozhuan">
        <van-row class="head-icon">
          <van-col span="8" @click="tointroduction">
            <van-image
              width="3rem"
              height="3rem"
              :src="require('../../assets/img/product.png')"
            />
          </van-col>
          <van-col span="8" @click="toinvestment"
            ><van-image
              width="3rem"
              height="3rem"
              :src="require('../../assets/img/publish.png')"
          /></van-col>
          <van-col span="8" @click="topool"
            ><van-image
              width="3rem"
              height="3rem"
              :src="require('../../assets/img/pool.png')"
          /></van-col>
        </van-row>
        <van-row class="head-title">
          <van-col span="8" @click="tointroduction"
            ><span>{{ $t('fund.desc') }}</span></van-col
          >
          <van-col span="8" @click="toinvestment"
            ><span>{{ $t('fund.fabu') }}</span></van-col
          >
          <van-col span="8" @click="topool"
            ><span>{{ $t('fund.biaoc') }}</span></van-col
          >
        </van-row>
      </van-row>
      <!-- 发布 -->
      <van-row class="publish">
        <van-col :span="24" style="padding:20px">
          <span>{{ info.title }}</span>
          <van-row class="publish-rate">
            <span>{{ info.fee }}%</span>
          </van-row>
          <span>{{ $t('fund.profit') }}</span>
          <van-row class="publish-msg">
            <span style="display:block">{{ info.content }}</span>
            <van-button class="submitBtn" @click="todetail(info.id)">{{
              $t('fund.submit')
            }}</van-button>
          </van-row>
        </van-col>
      </van-row>
      <!-- 发布列表 -->
      <van-row class="publish-list">
        <van-tabs  title-active-color="#ff571e" title-inactive-color="#cad0e6" class="main_tab">
          <van-tab :title="$t('fund.my')">
            <van-row class="details-data" v-for="item in list" :key="item.id">
              <van-col :span="8">
                <h4 style="color: rgb(0, 159, 128);">{{ item.fee }}%</h4>
                <span>利率</span>
              </van-col>
              <van-col :span="8" style="text-align: center;">
                <h4>{{ item.total }}</h4>
                <span>数量</span>
              </van-col>
              <van-col :span="8" style="text-align: right;">
                <h4>{{ item.days }}</h4>
                <span>时间</span>
              </van-col>
            </van-row>
          </van-tab>
        </van-tabs>
      </van-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      info: {}
    }
  },
  created() {
    this.getlist()
  },
  methods: {
    async getlist() {
      const { data } = await this.$http.get('/home/fund/publishlist')
      if (data) {
        if (data.code === 200) {
          // this.list = data.data.list
          this.info = data.data.info
        }
      }
    },
    // 产品介绍页
    tointroduction() {
      this.$router.push('/fund/introduction')
    },
    // 投资发布页
    toinvestment() {
      this.$router.push('/fund/investment')
    },
    // 投资标池页
    topool() {
      this.$router.push('/fund/pool')
    },
    todetail(id) {
      this.$router.push('/fund/investment/detail/' + id)
    }
  }
}
</script>

<style lang="less" scoped>
.maincontent {
  padding: 60px 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  background: #f7f7f7;
  // 轮播图
  .first-swipe {
    height: 11rem;
    .van-swipe-item img {
      width: 94%;
    }
  }
  // 三个图标
  .icon-tiaozhuan {
    background-color: #262848;
    color: #8686aa;
    height: 6.13333rem;
    .head-icon {
      border-top: 0.02667rem solid #232b40;
      padding-top: 0.93333rem;
    }
    .head-title {
      span {
        font-size: 0.93333rem;
        margin-top: 0.2rem;
      }
    }
    .head-icon div,
    .head-title div {
      display: flex;
      justify-content: center;
    }
  }
  // 发布
  .publish {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    background-color: #262848;
    color: #8686aa;
    display: flex;
    justify-content: center;
    .publish-rate {
      font-size: 2.66667rem;
      color: #00d25d;
      margin-top: 0.53333rem;
    }
    .publish-msg {
      margin-top: 0.53333rem;
      color: grey;
      font-size: 0.8rem;
    }
    .submitBtn {
      margin-top: 5%;
      width: 70%;
      letter-spacing: 0.53333rem;
      height: 3.2rem;
      font-size: 0.93333rem;
      color: #fff;
      background-color: #ff571e;
      border-radius: 0.53333rem;
      border: none;
    }
  }
  // 发布列表
  /deep/.van-tabs__nav--line {
    box-sizing: initial;
    height: 100%;
    padding-bottom: 15px;
  }
  /deep/.van-tab {
    font-size: 1rem;
  }
  /deep/.van-tab--active {
    color: #004ea3;
  }
  .details-data {
    padding: 0.8rem 1.06667rem;
    text-align: left;
    line-height: 1.6rem;
    border-top: 0.01rem solid #dcdcdc;
    background: #fff;
    h4 {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
